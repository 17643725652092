import { BrowserReporter, consola, isDebuggerEnable, isDebuggerGrouped, isLocalhost } from '@b2x/core/bundle';

import { buildTime, version } from './version';

const LOGGER_NAMESPACE = 'B2X@OHI';

function initializeLogger() {
  consola.setLogger(LOGGER_NAMESPACE, {
    reporters: [
      new BrowserReporter({
        title: `OHI@${version}-${buildTime}`,
        namespace: LOGGER_NAMESPACE,
        delay: 1500,
        grouped: isDebuggerGrouped(LOGGER_NAMESPACE),
        enabled: isDebuggerEnable(LOGGER_NAMESPACE) || isLocalhost(),
      }),
    ],
  });

  return consola.getLogger(LOGGER_NAMESPACE);
}

const logger = initializeLogger();

export const LOG_GROUPS = {
  BOOTSTRAP: 'Bootstrap',
  CONNECTIONS: 'Connections',
  NAVIGATION_ITEM: 'Navigation Item',
  ALERT_USER_MENU: 'Alert User Menu',
} as const;

export { logger };
