import { CONNECTIONS, LOG_LEVEL, getConnectionState } from '@b2x/core/bundle';
import { initializeStore } from '@seamless/store';

import type { AuthenticationState } from '@b2x/authentication-library';
import type { ProfileState } from '@b2x/profile-library';
import type { HeaderDispatchers } from '@dh-io-hp/header-connection';

import { addUnloadEvent, getDispatchers } from '../utilities/common';
import { getNavItemConfig, getUserMenuConfig } from '../utilities/helpers';
import { LOG_GROUPS, logger } from '../utilities/logger';

export async function handleNavigationItem() {
  const store = initializeStore();
  const userMenuConfig = await getUserMenuConfig();
  const headerDispatchers = await getDispatchers<HeaderDispatchers>('HP_HEADER_CONNECTION');

  const profileSubscription = store.subscribe(CONNECTIONS.PROFILE_CONNECTION_NAME, async (state: ProfileState) => {
    const authState = await getConnectionState<AuthenticationState>(CONNECTIONS.AUTH_CONNECTION_NAME);
    const navigationItemConfig = getNavItemConfig(state.activeProfile, authState, userMenuConfig);

    headerDispatchers.setNavigationItemsConfiguration(navigationItemConfig);

    logger('Update OneHeader navigation item with', {
      args: [navigationItemConfig.at(0)],
      group: LOG_GROUPS.NAVIGATION_ITEM,
      level: LOG_LEVEL.INFO,
    });
  });

  addUnloadEvent(profileSubscription);
}
